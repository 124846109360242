import { useContext } from 'react';
import { makeStyles } from '@mui/styles';
import XIcon from 'src/packages/icons/X';
import { IconButton } from '@mui/material';
import { MediaUploadContext } from 'src/packages/media-upload';
import { MediaLoader } from './MediaLoader';

const useStyles = makeStyles((theme) => {
	return {
		postImageFlexContainer: {
			display: 'flex',
			height: '100%',
			justifyContent: 'center'
		},
		postImageContainer: {
			width: '500px',
			textAlign: 'center',
			marginRight: (props) => (props.isVideoVaultPost ? '1rem' : '2rem'),
			backgroundColor: (props) => (props.customMediaUrl ? '#D3CFCE' : 'none'),
			position: 'relative',
			[theme.breakpoints.down('md')]: {
				margin: '0 auto'
			}
		},
		iconContainer: {
			padding: '0.5rem',
			position: 'absolute',
			right: 0
		},
		postImage: {
			maxHeight: (props) => (props.isVideoVaultPost ? '800px' : '500px'),
			maxWidth: '500px',
			alignSelf: 'center',
			[theme.breakpoints.down('md')]: {
				width: '100%',
				height: '100%'
			}
		}
	};
});

export const MediaSrc = ({ post }) => {
	const isVideoVaultPost = post?.node?.featuredPost?.videovault;
	const { customMediaUrl, isUploadingMedia, setCustomMediaUrl } =
		useContext(MediaUploadContext);
	const styles = useStyles({ customMediaUrl, isVideoVaultPost });

	if (isUploadingMedia) {
		return <MediaLoader />;
	}

	return (
		<div className={styles.postImageContainer}>
			{customMediaUrl && (
				<div className={styles.iconContainer}>
					<IconButton
						onClick={() => setCustomMediaUrl(false)}
						sx={{ backgroundColor: 'white' }}
						color="primary"
						aria-label="remove picture"
						component="label"
					>
						<XIcon />
					</IconButton>
				</div>
			)}
			<div className={styles.postImageFlexContainer}>
				<img
					className={styles.postImage}
					src={customMediaUrl || post?.node?.featuredPost?.postimage?.mediaItemUrl}
				/>
			</div>
		</div>
	);
};
