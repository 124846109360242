import {
	Box,
	Card,
	Divider,
	Grid,
	IconButton,
	Tooltip,
	Typography,
	Button
} from '@mui/material';
import green from '@material-ui/core/colors/green';
import CheckCircle from '@material-ui/icons/CheckCircle';

export const VideoVaultUsedButton = ({
	post,
	isPostSaved,
	setIsPostSaved,
	setSavedPostsPayload,
	handleSaveClick,
	isUpdatingSavedPosts
}) => {
	return (
		<Box
			sx={{
				alignItems: 'center',
				display: 'flex'
			}}
		>
			{isPostSaved ? (
				<Tooltip title="Unused Post">
					<IconButton
						disabled={isUpdatingSavedPosts}
						onClick={() => {
							setSavedPostsPayload(post.node.databaseId, true);
							setIsPostSaved(false);
						}}
						sx={{ color: green['600'] }}
					>
						<CheckCircle fontSize="large" />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Used Post">
					<IconButton
						disabled={isUpdatingSavedPosts}
						onClick={() => handleSaveClick()}
					>
						<CheckCircle fontSize="large" />
					</IconButton>
				</Tooltip>
			)}
		</Box>
	);
};
