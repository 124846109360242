import { createContext } from 'react';

export interface MediaBrowserContext {
	// useful global queries to use in media browser views and components
	queries?: {
		GET_CHILD_CATS_BY_PARENT_CAT_SLUG: any;
		GET_POSTS_BY_TAGS: any;
		GET_POSTS_BY_CATEGORY_IDS: any;
		GET_POSTS_BY_CATEGORY_NAMES: any;
		GET_POSTS_BY_CATEGORY: any;
		GET_POSTS_BY_TAGS_NO_CHILDREN: any;
		GET_POSTS_BY_ID: any;
		GET_SOCIAL_BY_SIZE_POSTS: any;
		GET_TEMPLATES_AND_DOWNLOADS_POSTS: any;
		GET_VIDEO_VAULT_POSTS: any;
	};
	initLocalStorageFilters?: any;
	resetLocalStorageFilter?: any;
	updateLocalStorageFilters?: any;
	removeLocalStorageFilter?: any;
	getLocalStorageFilters?: (key: string) => any;
	localStorageKeys?: any;
	localStorageEntryValues?: any;
	deleteLocalStorageFilter?: any;
}

export const MediaBrowserContext = createContext<MediaBrowserContext>(null);
