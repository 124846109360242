import * as React from 'react';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ContentCopy from '@material-ui/icons/ContentCopy';
import { Tooltip } from '@mui/material';
import { useUserStore } from 'src/packages/state';
import { useCustomCaptions } from 'src/packages/custom-captions';
import useClipboard from 'react-use-clipboard';

interface VideoVaultCardProps {
	post: any;
	setHashtagsCopied?: any;
	setCaptionCopied?: any;
	setTextOverlayTooltip?: any;
	textOverlayTooltip?: boolean;
	throwTooltip?: any;
	copyButtonTooltip?: any;
}

export const VideoVaultCard = ({
	post,
	setCaptionCopied,
	setHashtagsCopied,
	textOverlayTooltip,
	throwTooltip,
	copyButtonTooltip
}: VideoVaultCardProps) => {
	const { userDetails } = useUserStore();
	const {
		preparedCaption,
		preparedHashtags,
		preparedVideoTextOverlay,
		preparedVideoTopic,
		preparedSeoKeywords
	} = useCustomCaptions(
		userDetails?.user_metadata?.captionSettings,
		post?.node?.featuredPost?.recommendedcaption,
		post?.node?.featuredPost?.popularhashtags,
		post?.node?.featuredPost?.videotextoverlay,
		post?.node?.featuredPost?.topic,
		post?.node?.featuredPost?.seokeywords
	);
	const [isOverlayCopied, setIsOverLayCopied] = useClipboard(
		preparedVideoTextOverlay
	);
	const [isKeywordsCopied, setIsKeywordsCopied] =
		useClipboard(preparedSeoKeywords);
	return (
		<Card variant="outlined">
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Video Topic
					</Typography>
				</Stack>
				<Typography>{preparedVideoTopic}</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Agent Action
					</Typography>
				</Stack>
				<Typography>{post.node.featuredPost.agentaction}</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Text Overlay
					</Typography>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={copyButtonTooltip === 'overlay'}
					>
						<Chip
							label="Copy"
							icon={<ContentCopy />}
							size="medium"
							onClick={() => {
								setIsOverLayCopied();
								throwTooltip('overlay');
							}}
						/>
					</Tooltip>
				</Stack>
				<Typography>{preparedVideoTextOverlay}</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Suggested caption
					</Typography>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={copyButtonTooltip === 'caption'}
					>
						<Chip
							label="Copy"
							icon={<ContentCopy />}
							size="medium"
							onClick={() => {
								setCaptionCopied();
								throwTooltip('caption');
							}}
						/>
					</Tooltip>
				</Stack>
				<Typography>{preparedCaption}</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						Popular Hashtags
					</Typography>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={copyButtonTooltip === 'tags'}
					>
						<Chip
							label="Copy"
							icon={<ContentCopy />}
							size="medium"
							onClick={() => {
								setHashtagsCopied();
								throwTooltip('tags');
							}}
						/>
					</Tooltip>
				</Stack>
				<Typography>{preparedHashtags}</Typography>
			</Box>
			<Divider />
			<Box sx={{ p: 1.3 }}>
				<Stack direction="row" justifyContent="space-between" alignItems="center">
					<Typography
						color={'textPrimary'}
						sx={{ fontSize: '0.9rem' }}
						variant={'overline'}
					>
						SEO Keywords
					</Typography>
					<Tooltip
						arrow
						placement={'top'}
						title={'Copied!'}
						disableHoverListener
						open={copyButtonTooltip === 'keywords'}
					>
						<Chip
							label="Copy"
							icon={<ContentCopy />}
							size="medium"
							onClick={() => {
								setIsKeywordsCopied();
								throwTooltip('keywords');
							}}
						/>
					</Tooltip>
				</Stack>
				<Typography>{preparedSeoKeywords}</Typography>
			</Box>
		</Card>
	);
};
