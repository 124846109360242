import unidecode from 'unidecode';
import emojiRegex from 'emoji-regex';

// Function to preserve emojis and transliterate the rest
function preserveEmojis(text) {
	const regex = emojiRegex();
	let result = '';
	let lastIndex = 0;

	let match;
	while ((match = regex.exec(text)) !== null) {
		const emoji = match[0];
		const emojiIndex = match.index;

		// Add non-emoji characters without modification
		result += text.slice(lastIndex, emojiIndex);
		// Add the emoji itself
		result += emoji;

		lastIndex = regex.lastIndex;
	}

	result += text.slice(lastIndex);
	return result;
}

/**
 * Function to decode caption characters to ensure consistent posts across platforms
 * @param caption
 * @returns string
 */
export const cleanCaption = (caption: string) => {
	return preserveEmojis(caption);
};
