import createSvgIcon from '@material-ui/core/utils/createSvgIcon';

const CapcutIcon = createSvgIcon(
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 1425 1400"
		width="250"
		height="250"
	>
		<title>CapCut App logo</title>
		<path
			d="M440,476.09c-.06,4.36,0,8.73,0,13.09a2.65,2.65,0,0,0,1.58,2.41Q462.16,501.83,482.8,512c-13.64,6.7-27.23,13.49-40.85,20.22a2.91,2.91,0,0,0-2,2.39c0,4.37,0,8.75,0,13.12a19.6,19.6,0,0,0,16.62,17.4c1.72.16,3.44.13,5.16.13h76.38a22.06,22.06,0,0,0,5.5-.45A20,20,0,0,0,556.42,555a31.52,31.52,0,0,0,2.1-5.5c7.84,4.08,15.85,7.88,23.75,11.85a1.11,1.11,0,0,0,1.77-.9c0-4.52,0-9,0-13.56a2.3,2.3,0,0,0-1.54-2L516.18,512q33.23-16.49,66.47-33A2.24,2.24,0,0,0,584,477c0-4.52,0-9,0-13.54a1.07,1.07,0,0,0-1.54-.82l-24.08,11.94a20.65,20.65,0,0,0-3.13-7.31,19.9,19.9,0,0,0-14.75-8.54H458.59A19.63,19.63,0,0,0,440,476.09Z"
			transform="translate(-439.95 -458.69)"
			style={{ fill: '#FFF' }}
		/>
		<path
			d="M455.17,476.18a4.94,4.94,0,0,1,4.58-2.72h79.17a5,5,0,0,1,5,4.28c.1,1.33.05,2.66,0,4q-22.2,11-44.4,22c-15-7.36-29.91-14.81-44.86-22.22C454.73,479.74,454.35,477.83,455.17,476.18Z"
			transform="translate(-439.95 -458.69)"
			style={{ fill: '#FFF' }}
		/>
		<path
			d="M454.66,542.54q22.36-11.15,44.77-22.21c14.86,7.24,29.66,14.68,44.5,22-.1,1.92.37,4-.64,5.77a4.94,4.94,0,0,1-4.33,2.49H459.76a5,5,0,0,1-4.55-2.65C454.33,546.28,454.73,544.34,454.66,542.54Z"
			transform="translate(-439.95 -458.69)"
			style={{ fill: '#FFF' }}
		/>
		<path
			d="M440,476.09a19.63,19.63,0,0,1,18.6-17.39h81.94a19.9,19.9,0,0,1,14.75,8.54,20.65,20.65,0,0,1,3.13,7.31l24.08-11.94a1.07,1.07,0,0,1,1.54.82c0,4.51,0,9,0,13.54a2.24,2.24,0,0,1-1.39,2.12q-33.23,16.48-66.47,33L582.5,544.9a2.3,2.3,0,0,1,1.54,2c0,4.52,0,9,0,13.56a1.11,1.11,0,0,1-1.77.9c-7.9-4-15.91-7.77-23.75-11.85a31.52,31.52,0,0,1-2.1,5.5,20,20,0,0,1-12.79,9.87,22.06,22.06,0,0,1-5.5.45H461.76c-1.72,0-3.45,0-5.16-.13A19.6,19.6,0,0,1,440,547.77c0-4.37,0-8.75,0-13.12a2.91,2.91,0,0,1,2-2.39c13.62-6.73,27.21-13.52,40.85-20.22q-20.61-10.26-41.25-20.46a2.65,2.65,0,0,1-1.58-2.41C440,484.82,439.93,480.45,440,476.09Zm15.18.09c-.82,1.65-.44,3.56-.51,5.34,15,7.41,29.89,14.86,44.86,22.22q22.19-11,44.4-22c0-1.33.06-2.66,0-4a5,5,0,0,0-5-4.28H459.75A4.94,4.94,0,0,0,455.17,476.18Zm-.51,66.36c.07,1.8-.32,3.74.55,5.41a5,5,0,0,0,4.55,2.65H539a4.94,4.94,0,0,0,4.33-2.49c1-1.75.54-3.85.64-5.77-14.83-7.32-29.64-14.76-44.5-22Q477,531.44,454.66,542.54Z"
			transform="translate(-439.95 -458.69)"
		/>
	</svg>,
	'CapcutIcon'
);

export default CapcutIcon;
