import React, { useState, useContext } from 'react';
import { withStyles, makeStyles } from '@mui/styles';
import { Box, Dialog } from '@mui/material';
import MuiDialogContent from '@mui/material/DialogContent';
import { CustomCaptionsProvider } from 'src/packages/custom-captions';
import { FacebookProvider } from 'src/packages/facebook';
import { InstagramProvider } from 'src/packages/instagram';
import { LinkedinProvider } from 'src/packages/linkedin';
import { ProfileSelectProvider } from 'src/packages/social-profile-selector';
import { MediaUploadProvider } from 'src/packages/media-upload';
import PreviewMedia from './PreviewMedia';
import PublishMedia from './PublishMedia';
import { MediaSrc } from './MediaSrc';

const useStyles = makeStyles((theme) => ({
	layout: {
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column'
		}
	},
	dialog: {
		[theme.breakpoints.down('md')]: {
			margin: '0 auto',
			maxWidth: '100%'
		}
	},
	postImageContainer: {
		marginRight: '2rem',
		[theme.breakpoints.down('md')]: {
			margin: '0 auto'
		}
	},
	modalButtons: {
		[theme.breakpoints.down('md')]: {
			marginRight: '0',
			marginBottom: '1rem'
		}
	},
	buttonsContainer: {
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column'
		}
	},
	postImage: {
		width: '500px',
		height: '500px',
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: '100%'
		}
	}
}));

const DialogContent = withStyles((theme) => ({
	root: {
		padding: theme.spacing(2)
	}
}))(MuiDialogContent);

interface PostModalInterface {
	open?: boolean;
	setOpen?: any;
	post?: any;
}

export const MediaContainer = ({ open, setOpen, post }: PostModalInterface) => {
	const [publish, setPublish] = useState(false);
	const isVideoVaultPost = post?.node?.featuredPost?.videovault;

	const styles = useStyles();

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setPublish(false);
	};

	return (
		<>
			<MediaUploadProvider>
				<CustomCaptionsProvider>
					<FacebookProvider>
						<InstagramProvider>
							<LinkedinProvider>
								<ProfileSelectProvider>
									<Dialog
										maxWidth={isVideoVaultPost ? 'full' : 'lg'}
										fullWidth={true}
										onClose={handleClose}
										aria-labelledby="media-container"
										open={open}
										className={styles.dialog}
									>
										<DialogContent dividers>
											<Box display="flex" className={styles.layout}>
												<MediaSrc post={post} />
												{publish ? (
													<PublishMedia post={post} setPublish={setPublish} />
												) : (
													<PreviewMedia
														styles={styles}
														post={post}
														handleClose={handleClose}
														setPublish={setPublish}
													/>
												)}
											</Box>
										</DialogContent>
									</Dialog>
								</ProfileSelectProvider>
							</LinkedinProvider>
						</InstagramProvider>
					</FacebookProvider>
				</CustomCaptionsProvider>
			</MediaUploadProvider>
		</>
	);
};
