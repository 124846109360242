import { Box, Alert, AlertTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
	socialProfilesAlert: {
		[theme.breakpoints.down('md')]: {
			marginTop: '1rem'
		}
	}
}));

export const NoConnectedProfiles = () => {
	const navigate = useNavigate();
	const styles = useStyles();
	return (
		<Box
			className={styles.socialProfilesAlert}
			sx={{ mb: '1rem', cursor: 'pointer' }}
		>
			<Alert
				severity="warning"
				onClick={() => {
					navigate('/dashboard/social-profiles');
				}}
			>
				<AlertTitle>
					You need connect your Social Profiles in order to post and schedule posts.
					Click here to connect your profiles.
				</AlertTitle>
			</Alert>
		</Box>
	);
};
