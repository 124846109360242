import React, { useEffect, useContext } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
	Avatar,
	Box,
	Button,
	Divider,
	Drawer,
	Hidden,
	Link,
	Typography
} from '@mui/material';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BriefcaseIcon from '../../icons/Briefcase';
import CalendarIcon from '../../icons/Calendar';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import TemplateIcon from '../../icons/Template';
import CollectionIcon from '../../icons/Collection';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ChevronRight from '../../icons/ChevronRight';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import ChatAltIcon from '../../icons/ChatAlt';
import ClipboardListIcon from '../../icons/ClipboardList';
import FolderOpenIcon from '../../icons/FolderOpen';
import MailIcon from '../../icons/Mail';
import CameraIcon from '../../icons/Camera';
import ShareIcon from '../../icons/Share';
import InformationCircle from 'src/packages/icons/InformationCircle';
import Home from 'src/packages/icons/Home';
import ShoppingBagIcon from '../../icons/ShoppingBag';
import ShoppingCartIcon from '../../icons/ShoppingCart';
import UserIcon from '../../icons/User';
import ArrowRight from '../../icons/ArrowRight';
import EyeIcon from 'src/packages/icons/Eye';
import DownloadIcon from 'src/packages/icons/Download';
import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import { useAuth0 } from '@auth0/auth0-react';
import { useSubscriptionPlanDetails } from 'src/packages/hooks';
import { PaywallContext } from 'src/packages/paywall';
import { useUserStore } from 'src/packages/state';

interface DashboardSidebarProps {
	onMobileClose: () => void;
	openMobile: boolean;
}

const sections = [
	{
		title: 'Content And Captions',
		items: [
			{
				title: "This Month's Media",
				path: '/dashboard/this-months-media',
				icon: <CalendarIcon fontSize="small" />
			},
			{
				title: "Next Month's Media",
				path: '/dashboard/next-months-media',
				icon: <ArrowRight fontSize="small" />
			},
			{
				title: 'Content by Category',
				path: '/dashboard/content-by-category',
				icon: <CollectionIcon fontSize="small" />
			},
			{
				title: 'Social by Size',
				path: '/dashboard/social-by-size',
				icon: <TemplateIcon fontSize="small" />
			},
			{
				title: 'Saved Social',
				path: '/dashboard/saved-social',
				icon: <FavoriteIcon fontSize="small" />
			}
		]
	},
	{
		title: 'Marketing Resources',
		items: [
			{
				title: 'Video Vault',
				path: '/dashboard/video-vault',
				icon: <PlayArrowIcon fontSize="small" />
			},
			{
				title: 'Templates and Downloads',
				path: '/dashboard/templates-downloads',
				icon: <DownloadIcon fontSize="small" />
			}
			// {
			//   title: 'Listing Generator',
			//   path: '/dashboard/products',
			//   icon: <ShoppingCartIcon fontSize="small" />,
			// },
		]
	},
	{
		title: 'Publish It',
		items: [
			{
				title: 'Social Profiles',
				path: '/dashboard/social-profiles',
				icon: <ShareIcon fontSize="small" />
			},
			{
				title: 'Content Calendar',
				path: '/dashboard/calendar',
				icon: <CalendarIcon fontSize="small" />
			}
		]
	},
	{
		title: 'Admin Panel',
		items: [
			{
				title: 'Branding Center',
				path: '/dashboard/branding/captionSettings',
				icon: <EyeIcon fontSize="small" />
			},
			{
				title: 'Account',
				path: '/dashboard/account/profile',
				icon: <UserIcon fontSize="small" />
			},
			{
				title: 'Help and Support',
				path: '/dashboard/help-support',
				icon: <InformationCircle fontSize="small" />
			}
		]
	}
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
	const { onMobileClose, openMobile } = props;
	const location = useLocation();
	const { user } = useAuth0();
	const plan = useSubscriptionPlanDetails();
	const { userSubscriptionDetailsCopy } = useContext(PaywallContext);
	const { userDetails } = useUserStore();

	useEffect(() => {
		!userDetails?.user_metadata?.stripe &&
			sections.unshift({
				items: [
					{
						title: 'Home',
						path: '/dashboard',
						icon: <Home fontSize="small" />
					}
				],
				title: ''
			});
	}, [userDetails]);

	useEffect(() => {
		if (openMobile && onMobileClose) {
			onMobileClose();
		}
	}, [location.pathname]);

	const content = (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100%'
			}}
		>
			<Scrollbar options={{ suppressScrollX: true }}>
				<Hidden lgUp>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							p: 2
						}}
					>
						<RouterLink to="/">
							<Logo
								sx={{
									height: 40,
									width: 40
								}}
							/>
						</RouterLink>
					</Box>
				</Hidden>
				<Box sx={{ p: 2 }}>
					<Box
						sx={{
							alignItems: 'center',
							backgroundColor: (theme) => theme.palette.background.default,
							borderRadius: 1,
							display: 'flex',
							overflow: 'hidden',
							p: 2
						}}
					>
						<RouterLink to="/dashboard/account/profile">
							<Avatar
								src={user.picture}
								sx={{
									cursor: 'pointer',
									height: 48,
									width: 48
								}}
							/>
						</RouterLink>
						<Box sx={{ ml: 2 }}>
							<Typography color="textPrimary" variant="subtitle2">
								{user.name}
							</Typography>
							<Typography color="textSecondary" variant="body2">
								{plan.description}
								<Link color="primary" component={RouterLink} to="/pricing">
									{user.plan}
								</Link>
							</Typography>
						</Box>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ p: 2 }}>
					{sections.map((section) => (
						<NavSection
							key={section.title}
							pathname={location.pathname}
							// sx={{
							// 	'& + &': {
							// 		mt: 1
							// 	}
							// }}
							{...section}
						/>
					))}
				</Box>
				<Divider />
			</Scrollbar>
		</Box>
	);

	return (
		<>
			<Hidden lgUp>
				<Drawer
					anchor="left"
					onClose={onMobileClose}
					open={openMobile}
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							width: 280
						}
					}}
					variant="temporary"
				>
					{content}
				</Drawer>
			</Hidden>
			<Hidden lgDown>
				<Drawer
					anchor="left"
					open
					PaperProps={{
						sx: {
							backgroundColor: 'background.paper',
							height: 'calc(100% - 64px) !important',
							top: '64px !important',
							width: 280
						}
					}}
					variant="persistent"
				>
					{content}
				</Drawer>
			</Hidden>
		</>
	);
};

DashboardSidebar.propTypes = {
	onMobileClose: PropTypes.func,
	openMobile: PropTypes.bool
};

export default DashboardSidebar;
