export const customizeCaption = (
	captionSettings: any,
	recommendedCaption: string
) => {
	const CaptionMappings = {
		'[firstName]': captionSettings?.firstName,
		'[lastName]': captionSettings?.lastName,
		'[phone]': captionSettings?.phone,
		'[email]': captionSettings?.email,
		'[website]': captionSettings?.website,
		'[marketArea]': captionSettings?.marketArea,
		'[state]': captionSettings?.state,
		'[city]': captionSettings?.city,
		'[zipCode]': captionSettings?.zip,
		'[firmCompany]': captionSettings?.firm,
		'[@realestatehandle]': captionSettings?.socialHandle
	};

	return recommendedCaption
		.replaceAll(`[firstName]`, CaptionMappings['[firstName]'])
		.replaceAll(`[lastName]`, CaptionMappings['[lastName]'])
		.replaceAll(`[phone]`, CaptionMappings['[phone]'])
		.replaceAll(`[email]`, CaptionMappings['[email]'])
		.replaceAll(`[website]`, CaptionMappings['[website]'])
		.replaceAll(`[marketArea]`, CaptionMappings['[marketArea]'])
		.replaceAll(`[state]`, CaptionMappings['[state]'])
		.replaceAll(`[city]`, CaptionMappings['[city]'])
		.replaceAll(`[zipCode]`, CaptionMappings['[zipCode]'])
		.replaceAll(`[firmCompany]`, CaptionMappings['[firmCompany]'])
		.replaceAll(`[@realestatehandle]`, CaptionMappings['[@realestatehandle]']);
};
