import React from 'react';
import { MediaBrowserContext } from './MediaBrowserContext';
import { useSubscriptionPlanDetails } from 'src/packages/hooks/useSubscriptionPlanDetails';
import {
	GET_CHILD_CATS_BY_PARENT_CAT_SLUG,
	getPostsByTags,
	GET_POSTS_BY_CATEGORY_IDS,
	getPostsByCategoryNames,
	getPostsByCategory,
	getPostsByTagsNoChildren,
	getPostsById,
	getSocialBySizePosts,
	getTemplatesAndDownloadsPosts,
	getVideoVaultPosts
} from '../gqlQueries';

interface LocalStorageFilters {
	categoryNames: string[];
	tags: string[];
	chips: string[];
}

interface LocalStorageKeys {
	contentByCategory: string;
	thisMonthsMedia: string;
	nextMonthsMedia: string;
}
interface LocalStorageEntryValues {
	categoryName: string;
	tagName: string;
	chips: string;
}

export const MediaBrowserProvider: React.FC = ({ children }) => {
	const { status, hasUsedFreeTrial } = useSubscriptionPlanDetails();
	const hasActiveSubscription = !status || hasUsedFreeTrial;
	const GET_POSTS_BY_CATEGORY = getPostsByCategory(!hasActiveSubscription);
	const GET_POSTS_BY_CATEGORY_NAMES = getPostsByCategoryNames(
		!hasActiveSubscription
	);
	const GET_POSTS_BY_TAGS = getPostsByTags(!hasActiveSubscription);
	const GET_POSTS_BY_TAGS_NO_CHILDREN = getPostsByTagsNoChildren(
		!hasActiveSubscription
	);
	const GET_POSTS_BY_ID = getPostsById(!hasActiveSubscription);
	const GET_SOCIAL_BY_SIZE_POSTS = getSocialBySizePosts(!hasActiveSubscription);
	const GET_TEMPLATES_AND_DOWNLOADS_POSTS = getTemplatesAndDownloadsPosts(
		!hasActiveSubscription
	);
	const GET_VIDEO_VAULT_POSTS = getVideoVaultPosts(!hasActiveSubscription);

	const localStorageKeys: LocalStorageKeys = {
		contentByCategory: 'contentByCategory:selectedSubCategories',
		thisMonthsMedia: 'thisMonthsMedia:selectedCategories',
		nextMonthsMedia: 'nextMonthsMedia:selectedCategories'
	};

	const localStorageEntryValues: LocalStorageEntryValues = {
		categoryName: 'categoryName',
		tagName: 'tagName',
		chips: 'chips'
	};

	const getLocalStorageFilters = (key: string) => {
		return JSON.parse(localStorage.getItem(key));
	};

	const initLocalStorageFilters = (key: string, value: string) => {
		localStorage.setItem(key, value);
	};

	const resetLocalStorageFilter = (key: string, value: string) => {
		localStorage.setItem(key, value);
	};

	const deleteLocalStorageFilter = (key: string) => {
		localStorage.removeItem(key);
	};

	const removeLocalStorageFilter = (key: string, value: any, entryType: any) => {
		const localStorageValue = getLocalStorageFilters(key);
		switch (entryType) {
			case localStorageEntryValues.categoryName:
				const filtered = localStorageValue.categoryNames.filter(
					(id) => id !== value
				);
				localStorageValue.categoryNames = filtered;
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			case localStorageEntryValues.chips:
				const filteredChips = localStorageValue.chips.filter(
					(chip) => chip !== value
				);
				localStorageValue.chips = filteredChips;
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			case localStorageEntryValues.tagName:
				const filteredTags = localStorageValue.tags.filter((tag) => tag !== value);
				localStorageValue.tags = filteredTags;
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			default:
				break;
		}
	};

	const updateLocalStorageFilters = (
		key: string,
		value: any,
		entryType: any
	) => {
		const localStorageValue = getLocalStorageFilters(key);
		switch (entryType) {
			case localStorageEntryValues.categoryName:
				localStorageValue.categoryNames.push(value);
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			case localStorageEntryValues.chips:
				localStorageValue.chips = value;
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			case localStorageEntryValues.tagName:
				localStorageValue.tags.push(value);
				localStorageValue.chips.push(value);
				localStorage.setItem(key, JSON.stringify(localStorageValue));
				break;
			default:
				break;
		}
	};

	const queries = {
		GET_CHILD_CATS_BY_PARENT_CAT_SLUG,
		GET_POSTS_BY_TAGS,
		GET_POSTS_BY_CATEGORY_IDS,
		GET_POSTS_BY_CATEGORY_NAMES,
		GET_POSTS_BY_CATEGORY,
		GET_POSTS_BY_TAGS_NO_CHILDREN,
		GET_POSTS_BY_ID,
		GET_SOCIAL_BY_SIZE_POSTS,
		GET_TEMPLATES_AND_DOWNLOADS_POSTS,
		GET_VIDEO_VAULT_POSTS
	};

	return (
		<MediaBrowserContext.Provider
			value={{
				queries,
				initLocalStorageFilters,
				getLocalStorageFilters,
				resetLocalStorageFilter,
				updateLocalStorageFilters,
				removeLocalStorageFilter,
				localStorageKeys,
				localStorageEntryValues,
				deleteLocalStorageFilter
			}}
		>
			{children}
		</MediaBrowserContext.Provider>
	);
};
