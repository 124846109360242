import { prepareCaption, prepareHashtags as prepareText } from '../utils';

export const useCustomCaptions = (
	userCaptionSettings: any,
	postCaption: string,
	postHashtags: string,
	postVideoTextOverlay: string,
	postVideoTopic: string,
	postSeoKeywords: string
) => {
	const preparedCaption = prepareCaption(userCaptionSettings, postCaption);
	const preparedHashtags = prepareText(userCaptionSettings, postHashtags);
	const preparedVideoTextOverlay = prepareCaption(
		userCaptionSettings,
		postVideoTextOverlay
	);
	const preparedVideoTopic = prepareCaption(userCaptionSettings, postVideoTopic);
	const preparedSeoKeywords = prepareCaption(
		userCaptionSettings,
		postSeoKeywords
	);

	return {
		preparedCaption,
		preparedHashtags,
		preparedVideoTextOverlay,
		preparedVideoTopic,
		preparedSeoKeywords
	};
};
