import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

export const CaptionDetailsMessage = () => {
	const navigate = useNavigate();
	return (
		<>
			<Box sx={{ mb: '1rem', cursor: 'pointer' }}>
				<Alert
					severity="error"
					onClick={() => {
						navigate('/dashboard/branding/captionSettings');
					}}
				>
					<AlertTitle>
						<b>
							Before you can publish any posts, you need to enter your Custom Caption
							details. <br />
							Click here to enter your Custom Caption details.
						</b>
					</AlertTitle>
				</Alert>
			</Box>
		</>
	);
};
